import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useTranslation } from "react-i18next";
import { Tab, Nav } from "react-bootstrap";
import { useSelector,useDispatch} from "react-redux";
import FullPageLoader from '../../common/FullPageLoader';
import incentive_img from "../../../webroot/images/incentive_scheme_img.svg";
import AddIfIncentive from './AddIfIncentive';
import HandleIfAddIncentive from "../widgets/HandleIfAddIncentive";
import FormValidation from "../widgets/FormValidation";
import DataValidationForZero from "../widgets/DataValidationForZero";
import TargetListTable from "./TargetListTable";
import MasterIfIncentive from './MasterIfIncentive';
import ChangeHandler from "../widgets/ChangeHandler";
import { CommonActions,IfActions } from "../../../store/action";
import { toast } from "react-toastify";
import {IF_CARD} from "../../../config/constant"
import { IfService } from "../../../services/IfService";
import AddTargetData from "./AddTargetData";
import CustomValueContainer from '../../common/CustomValueContainer';
import CheckValidationFormField from "../widgets/CheckValidationFormField";


const IfIncentiveConfig = (props) => {
    const { t } = useTranslation("common");
    const dispatch = useDispatch(); 
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
    const [addIncentivepopup, setAddIncentivepopup] = useState(false);
    const [incentiveSchemeSelected, setIncentiveSchemeSelected] = useState('');
    const [incentiveList, setIncentiveList] = useState({});
    const [expiredIncentiveList, setExpiredIncentiveList] = useState({});
    const [schemeRoleId, setSchemeRoleId] = useState(5);
    const [deleteIncentivePopup, setDeleteIncentivePopup] = useState(false);
    const [modifyStatusPopup, setModifyStatusPopup] = useState(false);
    const [status, setStatus] = useState(false);
    const [incentiveConfig, setIncentiveConfig] = useState({});
    const [elementSelected, setElementSelected] = useState({});
    const [tempErrors, setTempErrors] = useState({});
    const [activeItem, setActiveItem] = useState(1);
    const [preExpandedTabs, setPreExpandedTabs] = useState(-1);
    const [subExpandedTabs, setSubExpandedTabs] = useState(-1);
    const [submitStatusPopup, setSubmitStatusPopup] = useState(false);
    // const [removeDetail, setRemoveDetail] = useState(false);
    // const [removeBanner, setRemoveBanner] = useState(false);
    // const [removeInfo, setRemoveInfo] = useState({});
    // const [removeBan, setRemoveBan] = useState({});
    const [schemeList, setSchemeList] = useState([]);
    const [targetList, setTargetList] = useState({});
    const commonRedux = useSelector((state) => state.common);
    const [targetSampleFile, setTargetSampleFile] = useState(""); 
    const [addTargetPopup, setAddTargetPopup] = useState(false); 
    const [minMaxDate, setMinMaxDate] = useState({minDate:null,maxDate:null}); 
    const [targetFilters, setTargetFilters] = useState({month : new Date().toISOString()?.split('T')[0]});
    const rolesList = useSelector((state) => { 
        return (state.common && state.common.getRoleList) ? state.common.getRoleList : [] 
    });
    const [schemeTypeId, setSchemeTypeId] = useState(localStorage.getItem("scheme_type_id")); 
    const userInfo = useSelector((state) => state.auth && state.auth.authUser && state.auth.authUser.user_data); 
    const [roleList, setRoleList] = useState([]);

    const getTargetListData = (param = {}) =>{
        let paramOb = param;
        if(typeof paramOb.page_no === "undefined"){
            paramOb.page_no = 1;
            paramOb.pagination = true;
        } 
        setLoading(true);
        CommonActions.getTargetListData(paramOb).then((res) => { 
            if (res.status === 200 && res) {
                setTargetList(res)
                setLoading(false); 
            } else {
                setTargetList(res)
                setLoading(false); 
            }
        }).catch(err => {
            setLoading(false);
        });  
    }
    //change Tab
    const changeActiveTab = (activetab) => {
        setActiveTab(activetab);
    }
    const showModalAddIncentivepopup = () => {
        if (activeTab === 1) {
            setAddIncentivepopup(true);
            document.body.classList.add("overflow-hidden");
        }
    }; 
    const hideModalAddIncentivepopup = () => {
        setAddIncentivepopup(false);
        document.body.classList.remove("overflow-hidden");

    };
    const setIncentiveType = (data) => {
        setIncentiveSchemeSelected(data.id)
        handleAddIncentive(data.id, data.name);
    }

    const handleAddIncentive = (value, incentiveName = null) => { 
        let {incentive_list, incentive_scheme_selected} = HandleIfAddIncentive(value, incentiveName,schemeList,incentiveList,incentiveSchemeSelected);

        localStorage.setItem("scheme_type_id", incentive_scheme_selected);
        setIncentiveList({...incentive_list})
        hideModalAddIncentivepopup();
    };


    const handleChangeSchemeRole = (SchemeRoleOption) => {
        setSchemeRoleId(SchemeRoleOption.value);
        getAllIfSchemes();
    };
 
     /*
    *VALIDATE FORM
    */
    const ValidationError = (name, value,element) => { 
        let incentive_list=JSON.parse(JSON.stringify(incentiveList));
        let indexKey = incentive_list[parseInt(element.scheme_type_id)].findIndex(
            (p) => p.id === element.id
        );
        let {tempErrors,formIsValid} = CheckValidationFormField(name,value,element,t);  
        let errors={[element.scheme_type_id]: {[indexKey]: tempErrors}}
        setTempErrors(errors);
        
    };

    const handleChange = (name, value, is_confirm, element, activeItem = 0) => { 
        let inputFieldName = name.split("_");
        inputFieldName.pop();
        let front_name = inputFieldName.join('_'); 
        if (['min_disb_per','max_disb_per'].includes(front_name) && element.form_config.FRESH_DISBURSAL_CARD || element.form_config.DEALER_ACTIVATED_CARD || element.form_config.RENEWAL_REPAYMENT_CARD || element.form_config.AGEING_CLOSED_LOAN){
            ValidationError(name,value,element);
        }

        let incentive_list = ChangeHandler(name, value, is_confirm, element,incentiveList);   
        if(name==='scheme_duration' && element.form_config.ADDITION_INC_CARD){
            let minmax={
                minDate:value.start,
                maxDate:value.end
            };
            setMinMaxDate({...minmax});
        }
        setActiveItem(activeItem);
        setIncentiveList(incentive_list);
    };
     
    /*
    *VALIDATE FORM
    */
    const validUpdateForm = (data, indexKey) => { 
        let {tempErrors, formIsValid} = FormValidation(data,t); 
        let errors={[data.scheme_type_id]: {[indexKey]: tempErrors}}
        setTempErrors(errors);
        return formIsValid;
    };

    /*
    *VALIDATE DATA FOR ZERO
    */
    const validateDataForZero=async(data) =>{
        return DataValidationForZero(data);
    };
    
    /*
    * SAVE IF SCHEMES
    */
    const saveIfData=async(inputField)=>{ 
        let ifobject={
            scheme_type_id:inputField.scheme_type_id,
            product_type:inputField.product_type,
            scheme_duration:inputField.scheme_duration,
            description:inputField.description,
            status: inputField.hasOwnProperty('status') ?  inputField.status : 1,
            applicable_to:inputField.applicable_to,
            scheme_duration_date : inputField.scheme_duration_date
        };
        if(inputField.copy_id){
            ifobject["copy_id"]=inputField.copy_id;
        }
        if (inputField.id) {
            ifobject["id"]=inputField.id;
        }
        if (inputField.form_config.FRESH_DISBURSAL_CARD || inputField.form_config.RENEWAL_REPAYMENT_CARD || inputField.form_config.DEALER_ACTIVATED_CARD || inputField.form_config.ACTIVATED_ONBOARD_CARD || inputField.form_config.AGEING_CLOSED_LOAN || inputField.form_config.PROFIT_SHARING_1){
            ifobject[inputField.form_config.KEY]=inputField[inputField.form_config.KEY];
        }
        if (inputField.form_config.ADDITION_INC_CARD || inputField.form_config.PROFIT_SHARING_2 || inputField.form_config.COMPLETE_COLLDOC){
            ifobject[inputField.form_config.KEY]=inputField[inputField.form_config.KEY];
        }
        if (inputField.form_config.ACTIVATED_ONBOARD_CARD){
            ifobject[inputField.form_config.KEY]=inputField[inputField.form_config.KEY];
        }

        for(let item of ifobject[inputField.form_config.KEY]){
            if(item.incentive_amount_inr){
                delete item.incentive_amount_inr;
            }
        } 
        IfService.saveIfScheme(ifobject).then(function (response){
            setLoading(false);
            if(response.data.status === 200){
                toast.success(response.data.message);
                getAllIfSchemes();
            }else{
                toast.error(response.data.message); 
            }
        }).catch(function (response) {
            setLoading(false);
        });
    }

    const handleUpdate = async (element, is_delete, childRefKey = '', indexKey = null) => {
        setLoading(true);
        if (childRefKey) {
            setSubExpandedTabs(childRefKey);
        }
        let incentive_list=JSON.parse(JSON.stringify(incentiveList));

        let inputField = incentive_list[parseInt(element.scheme_type_id)][indexKey];

        let otherIncentiveList = incentive_list[parseInt(element.scheme_type_id)].filter((list, index) => index !== indexKey);

        if (validUpdateForm(inputField, indexKey)) {
            let dataValidateForZero= await validateDataForZero(inputField);
            if(!dataValidateForZero){
                saveIfData(inputField);
            }else{
                setLoading(false);
                // setElementSelected(inputField);
                // setSubmitStatusPopup(validateDataForZero);
                toast.error("Zero is not allowed.")
            }
        } else {
            setLoading(false);
        }
    };

    // const handleDeleteImage = async(element) => {
    //     setRemoveBanner(true);
    //     setRemoveBan(element);
    // };

    const handleCancel = (element) => {
        setSubExpandedTabs("") //CLOSE CURRENT TAB
        let { scheme_type_id, id } = element;
        let incentive_list  = incentiveList;
        let main_incentive_list = localStorage.getItem("main_incentive_list")
            ? JSON.parse(localStorage.getItem("main_incentive_list"))
            : {};
        let main_index = 0;
        if (id) {
            main_index = main_incentive_list[parseInt(scheme_type_id)].findIndex(
                (p) => p.id === id
            );
        } else {
            main_index = main_incentive_list[parseInt(scheme_type_id)].length;
        }
        let main_data = main_incentive_list[parseInt(scheme_type_id)][main_index];

        let index = 0;
        if (id) {
            index = incentive_list[parseInt(scheme_type_id)].findIndex(
                (p) => p.id === id
            );
        } else {
            index = incentive_list[parseInt(scheme_type_id)].length - 1;
        }
        if (main_index === main_incentive_list[parseInt(scheme_type_id)].length) {
            // delete incentive_list[parseInt(scheme_type_id)][index];
            incentive_list[parseInt(scheme_type_id)] = incentive_list[parseInt(scheme_type_id)].filter((obj, key)=> key !== index)
            setIncentiveList(incentive_list)
        } else {
            incentive_list[parseInt(scheme_type_id)][index] = main_data;
            setIncentiveList(incentive_list);
        }
    };

    const confirmationModalResponse = (response) => {
        document.body.classList.remove("overflow-hidden");
        setDeleteIncentivePopup(false);
        if (response.response) {
            let newIncentives = incentiveList[response.type.scheme_type_id].filter(ob => (ob.id !== response.type.id || ob.temp_id !== response.type.temp_id));

            let finalOb = {}
            if (newIncentives.length) {
                finalOb = {
                    ...incentiveList,
                    [response.type.scheme_type_id]: newIncentives
                }
            } else {
                let newRecord =incentiveList;
                delete newRecord[response.type.scheme_type_id];
                finalOb = { ...newRecord }
            }
            setIncentiveList(finalOb)
        }
    }
    const showExpandedTabUuidTabs = (uuid) => {
        setPreExpandedTabs(uuid);
    }

    const showSubExpandedTabUuid = (uuid) => {
        setSubExpandedTabs(uuid);
    }

    const emptyErrors = ()=>{
        setTempErrors({})
    }
    const addMoreIncentive = (params)=>{   
        let incentive_list  = JSON.parse(JSON.stringify(incentiveList));
        let incentiveData =  incentiveList[params.scheme][params.index];
        let ob=IF_CARD[params.type];
        if(Object.keys(ob).length){
            incentiveData[params.type].push(ob); 
        }
        incentive_list[params.scheme][params.index] = incentiveData; 
        setIncentiveList(incentive_list);
    }

    const removeMoreIncentive = (params)=>{
        let incentive_list  = JSON.parse(JSON.stringify(incentiveList));
        let incentiveData =  incentiveList[params.scheme][params.index];
       
        incentiveData[params.type].splice(params.key, 1);
        incentive_list[params.scheme][params.index] = incentiveData;
        setIncentiveList(incentive_list);
        
    }

    const handleCopyIncentive = (data, value) => { 
        let incentive_list=incentiveList;
        let switch_value = value ? value : incentiveSchemeSelected;
        let {id, ...dataToCopy} = data;
       
        let currentIncentiveCount = (incentiveList[switch_value]) ? incentiveList[switch_value].length : 0;
        dataToCopy["temp_id"] = switch_value + '_' + currentIncentiveCount;
        dataToCopy["copy_id"] = id; 
      
    
        if (incentive_list[parseInt(switch_value)]) {
            incentive_list[parseInt(switch_value)].push(dataToCopy);
        } 

        localStorage.setItem("scheme_type_id", incentiveSchemeSelected);
        setIncentiveList(incentive_list);
    };

    const hideModalpopup = (value) => {
        //this.setState({ [value]: false });
        document.body.classList.remove("overflow-hidden");
    };

    const activeModalResponse=(response)=>{
        setModifyStatusPopup(false);
        if (response.response) {
            let element_selected = response.type;
            handleChange("status",!element_selected.status,true,element_selected);
        }
    }
 
    const getIncentiveConfig = () => {
        dispatch(IfActions.getIfConfig()).then((response)=>{
            if (response && response.status === 200) {
                let configResponse = response.data;
                if (Object.keys(configResponse).length > 0) {
                    setIncentiveConfig({...configResponse});
                }else {
                    toast.error(response && response.message);
                }
            }
        }); 
    };
     
    /*
    * GET IF SCHEME LIST
    */
    const getIfSchemeList = () => {
        dispatch(IfActions.ifSchemeList({'role_id':schemeRoleId })).then((response)=>{
            if (response.length){
                setSchemeList(response); 
            }else {
                toast.error(response && response.message);
            }
        }); 
    };


    const getAllIfSchemes = () => {
        setLoading(true);
        IfService.getAllIfSchme({ scheme_role_id:schemeRoleId }).then(function (response) {
            setLoading(false);
            if (response.data.status === 200) {
                if (Object.keys(response.data.data).length > 0) {
                    let activeResponse = response.data.data.active_grouped_incentive_data;
                    localStorage.setItem("main_incentive_list",JSON.stringify(activeResponse));
                    setIncentiveList(activeResponse);
                   
                    setExpiredIncentiveList(response.data.data.expired_grouped_incentive_data);
                }
            }
        }).catch(function (response) { setLoading(false); });
    };
    
    const showAddTargetPopup = () => {
        if (activeTab === 3) {
            setAddTargetPopup(true);
            document.body.classList.add("overflow-hidden");
        }
    };

    const hideModalAddTargetPopup = () => {
        setAddTargetPopup(false);
        let params = targetFilters;
        getTargetListData(params);
        document.body.classList.remove("overflow-hidden");
    
    };

    useEffect(() => {
        if (!Object.keys(incentiveConfig).length) {
            getIncentiveConfig();
        }
    },[incentiveConfig])

    useEffect(() => {
        if (!schemeList.length) {
            getIfSchemeList();
        }
    },[schemeList])

    useEffect(() => {
        if (!Object.keys(incentiveList).length) {
            getAllIfSchemes();
        }
    },[0]);

    useEffect(() => {
        if (!Object.keys(targetList).length) {
            getTargetListData({month : new Date().toISOString()?.split('T')[0]});
        }
        if(commonRedux?.getRoleList?.length){
            let ifRoles = commonRedux?.getRoleList?.filter(role => +role.if_status === 1 || +role.sfa_role_id === 52)?.map(ob => ({value : ob.sfa_role_id, label : ob.label}))
            setRoleList(ifRoles);
        }
    },[targetList])


    useEffect(() => {
        if(commonRedux.incentiveConfig?.sampleFiles && commonRedux.incentiveConfig.sampleFiles.length){
            const targetItem = commonRedux.incentiveConfig.sampleFiles?.find(item => item.type === "target");
            const targetUrl = targetItem ? targetItem.url : "";
            setTargetSampleFile(targetUrl)
        }
        // eslint-disable-next-line
    }, [commonRedux && commonRedux.incentiveConfig]); 
    return (
        <React.Fragment>
            <FullPageLoader show={loading} />
            <div className='container-fluid'>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                    <div className="tab-line white-bg p-l-r-15 minus-l-r-15 hading-tab-top">
                        <div className="top-heading">
                            <h1>{t('COMMON.ADD_IF_INCENTIVE')}</h1>

                            <Nav variant="pills" className="flex-column">
                                <div className="tab-list">
                                    <Nav.Item onClick={() => { changeActiveTab(1) }}>
                                        <Nav.Link eventKey="first">{t('COMMON.ACTIVE_SCHEME')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => { changeActiveTab(2) }}>
                                        <Nav.Link eventKey="second">{t('COMMON.EXPIRED_SCHEME')}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item onClick={() => { changeActiveTab(3) }}>
                                        <Nav.Link eventKey="third">{t('COMMON.TARGET_LIST')}</Nav.Link>
                                    </Nav.Item>

                                    <div className="basic-detail-filter">
                                        <fieldset className="single-select">
                                            <div className="material">
                                                <Select components={{ ValueContainer: CustomValueContainer }}
                                                value={rolesList.filter(({ value }) => value === schemeRoleId)}
                                                onChange={handleChangeSchemeRole}
                                                options={rolesList.filter(ob=>ob.if_status===1 || +ob.sfa_role_id === 52)}
                                                placeholder="Scheme Role"
                                                className="react-select"
                                                classNamePrefix="react-select"
                                                isSearchable={false}
                                                />
                                            </div>
                                        </fieldset>

                                    </div>
                                </div>
                            </Nav>
                        </div>

                        {activeTab === 1 && userInfo.config && userInfo.config.INCENTIVE_PAGE.hasOwnProperty('SCHEME_ADD') && (
                            <button className="btn-line btn-add-incentive" onClick={() => { showModalAddIncentivepopup() }}>
                                <i className="ic-add"></i>
                                <span>{t('FORM.ADD_NEW_SCHEME')}</span>
                            </button>
                        )}
                    </div>

                    <Tab.Content>
                        <Tab.Pane eventKey="first">
                        {Object.keys(incentiveList).length > 0 ?
                        (
                            <div className="add-incentive-accordion">
                                {Object.keys(incentiveList).map((element, index) => (
                                    <div key={"parent" + index} id={"parent" + index}>

                                        {element.length > 0 && Object.keys(incentiveConfig).length > 0 && (
                                            <MasterIfIncentive
                                                schemaId={element}
                                                activeTab={activeTab}
                                                minMaxDate={minMaxDate}
                                                element={incentiveList[element]}
                                                index={index}
                                                hideModalpopup={hideModalpopup}
                                                incentive_config={incentiveConfig}
                                                tempErrors={tempErrors[element] && tempErrors[element]}
                                                handleChange={(e, i, q, u) => {
                                                    handleChange(e, i, q, u);
                                                }}
                                                handleUpdate={(e, i, q, u) => {
                                                    handleUpdate(e, i, q, u);
                                                }}

                                                handleCancel={(e) => {
                                                    handleCancel(e);
                                                }}
                                                handleAddIncentive={(e) => {
                                                    handleAddIncentive(e);
                                                }}
                                                activeItem={activeItem}
                                                confirmationModalResponse={confirmationModalResponse}
                                                preExpandedTabs={preExpandedTabs}

                                                showExpandedTabUuid={showExpandedTabUuidTabs}

                                                subExpandedTabs={subExpandedTabs}
                                                showSubExpandedTabUuid={showSubExpandedTabUuid}

                                                incentive_list={incentiveList}
                                                loading={loading}
                                                emptyErrors={emptyErrors}
                                                addMoreIncentive = {addMoreIncentive}
                                                removeMoreIncentive = {removeMoreIncentive}
                                                handleCopyIncentive = {(e, q) => {
                                                    handleCopyIncentive(e, q);
                                                }}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        ):
                            (<div className="active-scheme-outer">
                                <div className="white-bg">
                                    <img src={incentive_img} className="" alt="" />
                                    <span>{t('COMMON.NO_INCENTIVE_FOUND')}</span>
                                    <button className="btn-primary"  onClick={showModalAddIncentivepopup}>
                                        {t('FORM.ADD_INCENTIVE')}                       
                                    </button>
                                </div>
                            </div>)
                        }
                        
                        </Tab.Pane>
                         <Tab.Pane eventKey="second">
                            {Object.keys(expiredIncentiveList).length > 0 ? (
                                <div className="add-incentive-accordion">
                                    {Object.keys(expiredIncentiveList).map((element, index) => (
                                        <div key={"parent" + index}>
                                            {element.length > 0 && Object.keys(incentiveConfig).length > 0 && (
                                                <MasterIfIncentive
                                                    schemaId={element}
                                                    activeTab={activeTab}
                                                    element={expiredIncentiveList[element]}
                                                    index={index}
                                                    hideModalpopup={hideModalpopup}
                                                    incentive_config={incentiveConfig}
                                                   
                                                    tempErrors={tempErrors[element] && tempErrors[element]}

                                                    handleChange={(e, i, q, u) => {
                                                        handleChange(e, i, q, u);
                                                    }}
                                                    handleUpdate={(e, i, q, u) => {
                                                        handleUpdate(e, i, q, u);
                                                    }}
                                                    handleCancel={(e) => {
                                                        handleCancel(e);
                                                    }}
                                                    handleAddIncentive={(e) => {
                                                        handleAddIncentive(e);
                                                    }}

                                                    preExpandedTabs={preExpandedTabs}

                                                    showExpandedTabUuid={showExpandedTabUuidTabs}

                                                    subExpandedTabs={subExpandedTabs}

                                                    showSubExpandedTabUuid={showSubExpandedTabUuid}

                                                    
                                                    incentive_list={expiredIncentiveList}
                                                    
                                                    addMoreIncentive = {addMoreIncentive}

                                                    removeMoreIncentive = {removeMoreIncentive}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="active-scheme-outer">
                                    <div className="white-bg">
                                        <img src={incentive_img} className="" alt="" />
                                        <span>{t('COMMON.NO_EXPIRE_SCHEME')}</span>
                                    </div>
                                </div>
                            )}
                        </Tab.Pane>
                        <Tab.Pane eventKey="third">
                            {
                                Object.keys(targetList).length ?
                                    <TargetListTable hideModalAddTargetPopup={hideModalAddTargetPopup} roleList={roleList} targetFilters={targetFilters} setTargetFilters={setTargetFilters} targetList={targetList} getTargetListData={getTargetListData} targetSampleFile={targetSampleFile}/>
                                    :
                                    <div className="active-scheme-outer">
                                        <div className="white-bg">
                                            <img src={incentive_img} className="" alt="" />
                                            <span>{t('COMMON.NO_TARGET_LIST')}</span>
                                            <button className="btn-primary"  onClick={showAddTargetPopup}>
                                                {t('FORM.ADD_TARGET')}
                                            </button>
                                        </div>
                                    </div>
                            }

                        </Tab.Pane>

                    </Tab.Content>
                </Tab.Container>

                {/* For Role Listing */}
                {activeTab !== 3 && <AddIfIncentive handleClose={hideModalAddIncentivepopup} handleChangeSchemeRole={(SchemeRoleOption) => handleChangeSchemeRole(SchemeRoleOption)} setIncentiveType={(data) => setIncentiveType(data)} isOpen={addIncentivepopup} />}
               

            </div>
        </React.Fragment>
    );

}


export default IfIncentiveConfig;
